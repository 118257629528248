import React from 'react';
import * as Realm from 'realm-web';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Card, LinearProgress, Link, Stack, Typography } from '@mui/joy';

import { ExperimentCategory, IExperiment, IExperimentSchedule } from '../../../models/Experiment';
import { useDispatch, useSelector } from '../../../slices/store';
import { selectCompletionForAllExperiments } from '../../../slices/experiments';
import _ from 'lodash';
import Strings from '../../../utils/string_dict';
import Modal from '../../../components/foundation/Modal';
import {
    flagResponsesInactive,
    isUserInCohort, reloadResponses, saveScheduledExperiments, selectProfile,
    selectSubscriptions,
    subscribeToExperiments,
} from '../../../slices/account';
import { DayOfWeek } from '../../../models/Account';
import PageTitle from "../../../components/foundation/PageTitle";
import ReactMarkdown from "react-markdown";

interface IExperimentsListProps {
    experimentsGroupedByCategory: Map<ExperimentCategory, IExperiment[]>;
    scheduledExperimentsByStartTime?: Map<number, IExperiment[]>;
    color?: string;
    beginAtUserStartOfWeek: undefined|boolean;
}

const ExperimentsList = function ({
    experimentsGroupedByCategory,
    scheduledExperimentsByStartTime,
    color, beginAtUserStartOfWeek
}: IExperimentsListProps) {
    const completionByExperimentId = useSelector(selectCompletionForAllExperiments);
    const subscriptions = useSelector(selectSubscriptions);
    const { pathname } = useLocation();
    const history = useHistory();
    const userInCohort = useSelector((state) => isUserInCohort(state));
    const [subscriptionModalOpen, setSubscriptionModalOpen] = React.useState(false);
    const [resubscriptionModalOpen, setResubscriptionModalOpen] = React.useState(false);
    const dispatch = useDispatch();
    const profile = useSelector(selectProfile);


    const isSubscribedToBox = () => {
        const subscribedExperimentIds = Object.keys(subscriptions);
        return !Array
            .from(experimentsGroupedByCategory.values())
            .some(experiments => experiments
                .some(experiment => !subscribedExperimentIds.some(subscribedExperimentId => subscribedExperimentId === experiment.id)));
    };

    const toggleSubscriptionModal = () => {
        setSubscriptionModalOpen(!subscriptionModalOpen);
    };

    const toggleResubscriptionModal = () => {
        setResubscriptionModalOpen(!resubscriptionModalOpen);
    };

    const handleSubscribeToExperiment = async () => {
        const dayOfWeek = new Date().getUTCDay();
        const userStartOfWeekDay = DayOfWeek[profile!['startOfWeek'] as keyof typeof DayOfWeek].valueOf() + 1;
        const oneDayInMilliSecs = 24 * 60 * 60 * 1000;

        let subscriptionStartTime: number;
        let subscribeToFirstWeekExperiments = false;

        if (!beginAtUserStartOfWeek || dayOfWeek === userStartOfWeekDay) {
            subscriptionStartTime = Date.now();
            subscribeToFirstWeekExperiments = true;
        } else if (dayOfWeek < userStartOfWeekDay) {
            subscriptionStartTime = Date.now() + ((userStartOfWeekDay - dayOfWeek) * oneDayInMilliSecs);
        } else {
            subscriptionStartTime = Date.now() + ((7 - (dayOfWeek - userStartOfWeekDay)) * oneDayInMilliSecs);
        }

        const experiments = experimentsGroupedByCategory.get(ExperimentCategory.AVAILABLE);
        if (experiments) {
            const firstBoxExperiments = experiments.filter(e => e.boxweek === 0);

            if (subscribeToFirstWeekExperiments) {
                await dispatch(subscribeToExperiments({experiments: firstBoxExperiments, subscriptionStartTime: subscriptionStartTime}));
            }

            const oneWeekInMilliSecs = 7 * oneDayInMilliSecs;
            const filteredExperiments = subscribeToFirstWeekExperiments ? experiments.filter(e => e.boxweek !== 0) : experiments;
            const scheduledExperiments = filteredExperiments.map(e => {
               return {startTimeUTC: subscriptionStartTime + (e.boxweek * oneWeekInMilliSecs), experiments: [new Realm.BSON.ObjectId(e.id)]} as IExperimentSchedule;
            });

            await dispatch(saveScheduledExperiments(scheduledExperiments));
        }
        toggleSubscriptionModal();
    };

    const handleResubscribeToExperiment = async () => {
        const experiments: IExperiment[] = [];
        experimentsGroupedByCategory.forEach(groupedExperiments => experiments.push(...groupedExperiments));

        const existingSubscriptions = Object
            .entries(subscriptions)
            .filter(entry => experiments.some(experiment => experiment.id === entry[0]))
            .map(entry => entry[1]);
        await flagResponsesInactive(existingSubscriptions);
        await dispatch(reloadResponses(existingSubscriptions.map(s => s.id)));
        await dispatch(subscribeToExperiments({experiments: experiments, subscriptionStartTime: Date.now()}));
        toggleResubscriptionModal();
    };

    const getCategoryTitle = (experimentCategory: ExperimentCategory) => {
        switch (experimentCategory) {
            case ExperimentCategory.ACTIVE:
                return Strings.active_experiments;
            case ExperimentCategory.AVAILABLE:
                return Strings.available_experiments;
            case ExperimentCategory.SUGGESTED:
                return Strings.suggested_experiments;
            case ExperimentCategory.COMPLETED:
                return Strings.completed_experiments;
            case ExperimentCategory.SCHEDULED:
                return Strings.scheduled_experiments;
        }
    };


    const getBody = (experimentCategory: ExperimentCategory, experiments: IExperiment[]) => {
        if (experimentCategory === ExperimentCategory.SCHEDULED && scheduledExperimentsByStartTime && scheduledExperimentsByStartTime.size !== 0 ) {
            return getScheduledExperimentsBody(experimentCategory)
        } else if (experiments.length !== 0)  {
            return getNonScheduledExperimentsBody(experimentCategory, experiments);
        }
    }

    const getExperimentDescFirstParagraph = (experiment: IExperiment) => {
        const sorted = _.sortBy(experiment.desc.filter(d => d['type'] === 'para' || d['type'] === 'markdown'), ['index']);
        if (sorted.length > 0) {
            return {type: sorted[0]['type'], content: sorted[0]['content'] }
        } else {
            return null;
        }
    }
    const getScheduledExperimentsBody = (experimentCategory: ExperimentCategory) => {
        return <Stack spacing={2}>
            <Typography level="h5" sx={{ mb: 2, mt: 2, fontWeight: 'lg',fontSize: '0.9rem'}}>
                {getCategoryTitle(experimentCategory)}
            </Typography>
            <Card variant="outlined">
                {Array.from(scheduledExperimentsByStartTime!)
                    .sort(([startTime1, _], [startTime2, __]) => startTime1 - startTime2 ).map(([startUTCTime, scheduledExperiments]) => {
                        return (
                            <Stack spacing={2}>
                                <Typography level="body1" sx={{ mb: 1, mt: 2, fontWeight: 'lg', textAlign:"center", fontStyle: 'italic', fontSize: '0.8rem' }} > {Strings.starting_on} {new Date(startUTCTime).toDateString()} </Typography>
                                {scheduledExperiments.map(experiment => {
                                    let firstDescPara = getExperimentDescFirstParagraph(experiment);

                                    return (
                                        <Card
                                            key={experiment.id}
                                            variant="soft"
                                            sx={{
                                                '&:hover, &:focus-within': { bgcolor: 'background.level2' },
                                            }}
                                        >
                                            <Typography sx={{ fontSize: '0.9rem'}}>
                                                {experiment.name}
                                            </Typography>
                                            <Stack spacing={1}>
                                                <Link
                                                    overlay
                                                    textColor="inherit"
                                                    underline="none"
                                                    onClick={() => {
                                                        history.push(`${pathname}/${experiment.id}`);
                                                    }}
                                                >
                                                </Link>
                                                {firstDescPara && firstDescPara['type'] === 'para' && <Typography level="body2"  sx={{fontSize: '0.8rem'}}> {firstDescPara["content"]} </Typography>}
                                                {firstDescPara && firstDescPara['type'] === 'markdown' &&
                                                    <ReactMarkdown
                                                        children={firstDescPara["content"]}
                                                        components={{
                                                            h1: ({ children }) => <PageTitle>{children}</PageTitle>,

                                                            h2: ({ children }) => (
                                                                <Typography level="h4" component="h2" color="primary" sx={{ mt: 4 }}>
                                                                    {children}
                                                                </Typography>
                                                            ),
                                                            li: ({ children }) => <li style={{ marginTop: 2, fontSize: '0.8rem' }}>{children}</li>,

                                                            p: ({ children }) => <Typography sx={{ mt: 2, fontSize: '0.8rem'  }}>{children}</Typography>,

                                                            a: ({ children, href }) => <Link href={href}>{children}</Link>,
                                                        }}
                                                    />}                                                <Typography
                                                    level="body3">{experiment.days.length} {Strings.day_s_}</Typography>
                                            </Stack>
                                        </Card>
                                    );
                                })}
                            </Stack>
                        );
                    })
                }
            </Card>

        </Stack>;
    }

    const getNonScheduledExperimentsBody = (experimentCategory: ExperimentCategory, experiments: IExperiment[]) => {
        return <Stack spacing={2}>
            <Typography level="h5" sx={{ mb: 2, mt: 2, fontWeight: 'lg', fontSize: '0.9rem'}}>
                {getCategoryTitle(experimentCategory)}
            </Typography>
            {experiments
                .sort((e1, e2) => e1.boxweek - e2.boxweek )
                .map((experiment) => {
                    let firstDescPara = getExperimentDescFirstParagraph(experiment);
                    const completion = completionByExperimentId[experiment.id];
                    return (
                        <Card
                            key={experiment.id}
                            variant="outlined"
                            sx={{
                                backgroundColor: color || undefined,
                                '&:hover, &:focus-within': { bgcolor: 'background.level2' },
                            }}
                        >
                            <Typography sx={{ fontSize: '0.9rem'}}>
                                {experiment.name}
                            </Typography>
                            <Stack spacing={1}>
                                <Link
                                    overlay
                                    textColor="inherit"
                                    underline="none"
                                    onClick={() => {
                                        history.push(`${pathname}/${experiment.id}`);
                                    }}
                                >
                                </Link>
                                {firstDescPara && firstDescPara['type'] === 'para' && <Typography level="body2"  sx={{fontSize: '0.8rem'}}> {firstDescPara["content"]} </Typography>}
                                {firstDescPara && firstDescPara['type'] === 'markdown' &&
                                    <ReactMarkdown
                                    children={firstDescPara["content"]}
                                    components={{
                                        h1: ({ children }) => <PageTitle>{children}</PageTitle>,

                                        h2: ({ children }) => (
                                            <Typography level="h4" component="h2" color="primary" sx={{ mt: 4 }}>
                                                {children}
                                            </Typography>
                                        ),
                                        li: ({ children }) => <li style={{ marginTop: 2, fontSize: '0.8rem' }}>{children}</li>,

                                        p: ({ children }) => <Typography sx={{ mt: 2, fontSize: '0.8rem' }}>{children}</Typography>,

                                        a: ({ children, href }) => <Link href={href}>{children}</Link>,
                                    }}
                                />}
                                {completion !== undefined ? (
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <Typography level="body3">
                                            {completion}
                                            {Strings.percent_completed}
                                        </Typography>
                                        <LinearProgress determinate value={completion} />
                                    </Stack>
                                ) : (
                                    <Typography
                                        level="body3">{experiment.days.length} {Strings.day_s_}</Typography>
                                )}
                            </Stack>
                        </Card>
                    );
                })}
        </Stack>;
    }

    const getModalChildren = () => {
        return <div>
            <Typography level="h6"> {Strings.confirm_experiment_subscription} </Typography>
        </div>
    };

    const getResubModalChildren = () => {
        return <div>
            <Typography level="h6"> {Strings.confirm_restart_subscription} </Typography>
            <br />
        </div>
    };

    return (
        <div>
            {!userInCohort &&
                <div>
                    <Button
                        onClick={toggleSubscriptionModal}
                        style={{left: "12.5%", width: "70%"}} sx={{ mb: 2, mt: 4, fontWeight: 'lg', fontSize: '0.8rem'}}
                        disabled={isSubscribedToBox()}
                    >
                        {isSubscribedToBox() ? Strings.already_subscribed : Strings.subscribe_to_box}
                    </Button>
                    <br/>
                    <br/>
                    <br/>
                </div>
            }

            <Stack spacing={5}>
                {Array.from(experimentsGroupedByCategory).map(([experimentCategory, experiments]) => {
                    return getBody(experimentCategory, experiments);
                })}
            </Stack>

            <Modal
                headerTitle={Strings.confirm_subscription}
                isOpen={subscriptionModalOpen}
                onDismiss={toggleSubscriptionModal}
                className={'ion-modal-small'}
                onAction={handleSubscribeToExperiment}
                children={getModalChildren()}
            />

            <Modal
                headerTitle={Strings.confirm_resubscription}
                isOpen={resubscriptionModalOpen}
                onDismiss={toggleResubscriptionModal}
                className={'ion-modal-small'}
                onAction={handleResubscribeToExperiment}
                children={getResubModalChildren()}
            />

        </div>
    );
};

export default ExperimentsList;
